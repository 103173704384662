<footer>
    <div class="pos-rel remove-tablet-wrapper-padding remove-mobile-wrapper-padding">
        <div [class.crankle]="needCrankle()"></div>
    </div>
    <jsdaddy-section-wrapper black>
        <div class="container column">
            <div class="footer row jc-sb">
                <div class="content">
                    <a routerLink="/" class="logo" [attr.aria-label]="'Home' | anchorLabel"></a>
                    <div class="info row">
                        <div class="row">
                            <div aria-label="Send email" class="email column">
                                <p class="title">Let's Discuss</p>
                                <a [href]="corporateEmail | email" class="text">{{
                                    corporateEmail
                                }}</a>
                            </div>
                            <!-- <jsdaddy-upwork-svg class="hide-desktop hide-mobile" [isHeader]="false" /> -->
                        </div>
                        <div class="location column">
                            <p class="title">Location</p>
                            @for (location of locationItems; track location) {
                                <a
                                    class="text"
                                    [href]="
                                        'https://www.google.com/maps/search/?api=1&query=' +
                                        location
                                    "
                                    target="_blank"
                                    [attr.aria-label]="'Open Google Maps for ' + location">
                                    {{ location }}
                                </a>
                            }
                        </div>
                        <jsdaddy-upwork-svg class="hide-tablet hide-mobile" [isHeader]="false" />
                    </div>
                    @if (!isContactUsComponent()) {
                        <jsdaddy-button
                            [link]="contactUsPath"
                            type="primary-yellow"
                            class="skype-button"
                            title="Contact Us" />
                    }
                </div>
                <div class="additional column">
                    <div aria-hidden="true" (click)="toTop()" class="chevrone-up"></div>
                    <jsdaddy-upwork-svg
                        class="hide-tablet hide-desktop"
                        [smallSize]="true"
                        [isHeader]="false" />
                    <div class="row jc-fe">
                        <jsdaddy-linkedin class="linkedin hide-tablet" />
                    </div>
                </div>
            </div>
            <div class="row copyright-block jc-sb">
                <p class="text ls-25 uppercase white copyright-text">
                    {{ copyrightText }} {{ version }}
                </p>
                <jsdaddy-upwork-svg
                    class="hide-mobile hide-desktop ais-c"
                    [smallSize]="true"
                    [isHeader]="false" />
                <jsdaddy-linkedin class="ais-c hide-mobile hide-desktop" />
            </div></div
    ></jsdaddy-section-wrapper>
</footer>
