import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, inject } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonComponent } from '@shared/button/button.component';
import { GridComponent } from '@shared/grid/grid.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { OpenSourcePipe } from './open-source.pipe';
import { OpenSourceService } from './open-source.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-open-source',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        AssetPipe,
        OpenSourcePipe,
        ButtonComponent,
        HeaderTitleComponent,
        GridComponent,
        NgOptimizedImage,
        AnchorLabelPipe,
    ],
    providers: [OpenSourceService],
    templateUrl: './open-source.component.html',
    styleUrl: './open-source.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenSourceComponent {
    public readonly assetPath = 'content/home/open-source';
    public readonly assetPathShared = 'shared';
    public readonly jsdaddyGithub = 'https://github.com/JsDaddy/';

    private readonly service = inject(OpenSourceService);

    public projects = toSignal(this.service.getProjects());
    public maskProps = toSignal(this.service.getPropsList());
}
