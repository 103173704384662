import { Pipe, PipeTransform } from '@angular/core';
import { screenSizeDesktop, screenSizeMobile } from '@shared/screen-size/screen-size.const';

@Pipe({
    name: 'rightContent',
    standalone: true,
})
export class RightContentPipe implements PipeTransform {
    public transform(isShow: boolean, innerWidth: number): string {
        if (isShow) {
            return 'hide';
        }
        if (innerWidth > screenSizeDesktop) {
            return 'desktop';
        }
        return innerWidth <= screenSizeMobile ? 'mobile' : 'tablet';
    }
}
