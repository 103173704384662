import { DOCUMENT, isPlatformServer, LowerCasePipe, NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    inject,
    input,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    signal,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ResizeService } from '@shared/resize/resize.service';
import { tap } from 'rxjs';
import { TechnologiesSliderPipe } from './technologies-slider.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseSliderComponent } from '@shared/slider-enable-disable/base-slider.component';
import { SliderEnableDisableService } from '@shared/slider-enable-disable/slider-enable-disable.service';
import { SliderEnableDisablePipe } from '@shared/slider-enable-disable/slider-enable-disable.pipe';
import { screenSizeDesktop, screenSizeMobile } from '@shared/screen-size/screen-size.const';

@Component({
    selector: 'jsdaddy-technologies-slider[technologies]',
    standalone: true,
    imports: [
        LowerCasePipe,
        AssetPipe,
        TechnologiesSliderPipe,
        SliderEnableDisablePipe,
        NgOptimizedImage,
    ],
    templateUrl: './technologies-slider.component.html',
    styleUrl: './technologies-slider.component.scss',
    providers: [SliderEnableDisableService],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechnologiesSliderComponent extends BaseSliderComponent implements AfterViewInit {
    public technologies = input.required<string[] | undefined>();
    public itemsPerSlide = signal<number>(5);
    public slideInBlockLength = computed(
        () => (this.technologies()?.length || 0) - this.itemsPerSlide() + 1
    );

    public slideWidth = signal<number>(0);
    public slidesElement = viewChild<string, ElementRef<HTMLElement>>('slides', {
        read: ElementRef,
    });
    public slide = viewChild<string, ElementRef<HTMLElement>>('slide', { read: ElementRef });

    public readonly assetPathArrowButton = 'shared/arrow-button';

    private readonly resizeService = inject(ResizeService);
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly document = inject(DOCUMENT);

    public override ngAfterViewInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        super.ngAfterViewInit();
        const body: HTMLElement | null = this.document.querySelector('body');
        if (body) {
            this.resizeService
                .createResizeSequence(body)
                .pipe(
                    tap((width) => {
                        if (width < screenSizeDesktop && width > screenSizeMobile) {
                            this.itemsPerSlide.set(4);
                        } else if (width < screenSizeMobile) {
                            this.itemsPerSlide.set(3);
                        } else {
                            this.itemsPerSlide.set(5);
                        }
                        const numTechnologies = this.technologies()?.length || 0;
                        this.slideWidth.set(
                            numTechnologies >= this.itemsPerSlide()
                                ? 100 / this.itemsPerSlide()
                                : 100 / numTechnologies
                        );
                    }),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe();
        }
    }
}
