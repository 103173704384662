import { isPlatformServer } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    input,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    ViewEncapsulation,
} from '@angular/core';
import { ButtonComponent } from '@shared/button/button.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { EmailPipe } from '@shared/email/email.pipe';
import { ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { LinkedinComponent } from '@shared/linkedin/linkedin.component';
import { UpworkSvgComponent } from '@shared/upwork-svg/upwork-svg.component';
import { AppPath } from '@shared/routes/app-routing.const';
import { CurrentRouteService } from '@shared/current-route/current-route.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ContactUsComponent } from '@content/contact-us/contact-us.component';
import { map } from 'rxjs/internal/operators/map';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-footer',
    standalone: true,
    imports: [
        RouterLinkWithHref,
        ButtonComponent,
        SectionWrapperComponent,
        EmailPipe,
        LinkedinComponent,
        UpworkSvgComponent,
        AnchorLabelPipe,
    ],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
    public needCrankle = input<boolean | null>(false);

    private readonly currentRouteService = inject(CurrentRouteService);
    public isContactUsComponent = toSignal(
        this.currentRouteService.currentRoute$.pipe(
            map((route: ActivatedRoute) => route.component === ContactUsComponent)
        )
    );

    public readonly locationItems: string[] = ['BGR, Sveti Vlas', 'UA, Ivano-Frankivsk'];
    public readonly corporateEmail = 'sales@jsdaddy.io';
    public readonly copyrightText = `© JSdaddy, 2016-${new Date().getFullYear()}, All Rights Reserved`;
    public readonly contactUsPath = `/${AppPath.CONTACT_US}`;
    private readonly platformId = inject<string>(PLATFORM_ID);

    public version = RELEASE_VERSION;

    public toTop(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
}
