<a
    class="img"
    href="https://www.linkedin.com/company/jsdaddy/"
    target="_blank"
    aria-label="Read more about JSDaddy on linkedin">
    <img
        width="15"
        height="15"
        [ngSrc]="'linkedin' | asset: 'content/home/shared'"
        alt="Close button" />
</a>
