import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { GridComponent } from '@shared/grid/grid.component';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonComponent } from '@shared/button/button.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-company-id',
    standalone: true,
    imports: [GridComponent, AssetPipe, ButtonComponent, HeaderTitleComponent, NgOptimizedImage],
    templateUrl: './company-id.component.html',
    styleUrl: './company-id.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyIdComponent {
    private readonly companyIdLandingPath = 'https://companyid.io/';

    public readonly companyIdLandingContactUs = `${this.companyIdLandingPath}contact-us`;
    public readonly companyIdLandingPricing = `${this.companyIdLandingPath}pricing`;
    public readonly assetPath = 'content/home/company-id';
}
