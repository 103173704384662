<section
    class="why-choose-section column remove-right-desktop-wrapper-padding remove-right-tablet-wrapper-padding remove-right-mobile-wrapper-padding">
    <div class="section-title column ai-c jc-sb">
        <div class="section-title-delimiter"></div>
        <div class="section-title-content row ai-c jc-sb">
            <jsdaddy-header-title title="Why People Choose Us" subtitle="JS DADDY" needStar black />
            <ng-container *ngTemplateOutlet="controls" />
        </div>
    </div>
    <div class="slider">
        <div #slides class="slides row">
            @for (card of cards; track card.title; let l = $last) {
                <jsdaddy-why-choose-card #slide class="slide ai-c row" [card]="card" />

                @if (!l) {
                    <img
                        class="delimiter"
                        width="56"
                        height="254"
                        [ngSrc]="'slider-delimiter' | asset: assetPath"
                        alt="Slider delimiter" />
                }
            }
            <div class="fake-block hide-mobile"></div>
        </div>
    </div>
    <div class="section-mobile-controls">
        <ng-container *ngTemplateOutlet="controls" />
        <ng-container *ngTemplateOutlet="glasses" />
    </div>
    <div class="section-footer row jc-sb">
        <jsdaddy-button [link]="contactUsPath" type="primary-yellow" title="Want to Discuss!" />
        <ng-container *ngTemplateOutlet="glasses" />
    </div>
</section>

<ng-template #controls>
    <div class="controls row jc-sb">
        <jsdaddy-arrow-button
            direction="back"
            (click)="goToSlide(false)"
            [enabled]="activeSlideIndex() | sliderEnableDisable" />
        <jsdaddy-arrow-button
            (click)="goToSlide()"
            [enabled]="activeSlideIndex() | sliderEnableDisable: true : cards.length" />
    </div>
</ng-template>

<ng-template #glasses>
    <img [ngSrc]="'glasses' | asset: assetPath" alt="Glasses" width="322.41" height="162.53" />
</ng-template>
