// type-coverage:ignore-next-line
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { catchError, EMPTY, from, Observable, switchMap, throwError } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

declare const grecaptcha: {
    ready: (cb: () => void) => void;
    execute: (token: string, action: { action: string }) => string;
};

@Injectable()
export class RecaptchaService {
    private readonly platformId = inject<string>(PLATFORM_ID);

    public recaptchaReady(): Observable<boolean> {
        return new Observable((subscriber) => {
            grecaptcha.ready(() => {
                subscriber.next(true);
                subscriber.complete();
            });
        });
    }

    public recaptcha(action: string): Observable<string> {
        if (isPlatformServer(this.platformId)) {
            return EMPTY;
        }

        return this.recaptchaReady().pipe(
            switchMap(() => {
                return from(grecaptcha.execute(RECAPTCHA_CLIENT_KEY, { action }));
            }),
            catchError((error) => {
                // eslint-disable-next-line no-console
                console.error('recaptcha error', { error });
                return throwError(
                    () => new HttpErrorResponse({ statusText: 'Recaptcha Error', error })
                );
            })
        );
    }
}
