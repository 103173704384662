import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    inject,
    input,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonComponent } from '@shared/button/button.component';
import { FormSendingDialogComponent } from '@shared/form-sending-dialog/form-sending-dialog.component';
import { InputComponent } from '@libraries/input/input.component';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { CareerDetailsScrollDirective } from '../career-details-scroll.directive';
import { CareerDetailsService } from '../career-details.service';
import { CareerDetailsTellUsService } from './career-details-tell-us.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-career-details-tell-us',
    standalone: true,
    imports: [
        InputComponent,
        ButtonComponent,
        AssetPipe,
        CareerDetailsScrollDirective,
        NgxFileDropModule,
        FormSendingDialogComponent,
        ReactiveFormsModule,
        NgOptimizedImage,
    ],
    providers: [CareerDetailsTellUsService],
    templateUrl: './career-details-tell-us.component.html',
    styleUrl: './career-details-tell-us.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerDetailsTellUsComponent {
    public title = input.required<string>();

    public readonly assetPathContactForm = 'contact-form';

    private readonly careerDetailsService = inject(CareerDetailsService);
    private readonly service = inject(CareerDetailsTellUsService);

    public fileAndError = toSignal(this.service.fileAndError$);

    public form = this.service.form;

    public section = this.careerDetailsService.getSection('Send your CV');
    public readonly steps = this.service.steps;

    public onSectionChange(sectionId: string): void {
        this.careerDetailsService.changeSection(sectionId);
    }

    public clearFile(): void {
        this.service.clearFile();
    }

    public apply(): void {
        this.service.sendEmail(this.title());
    }

    public dropped(files: NgxFileDropEntry[]): void {
        this.service.dropped(files);
    }
}
