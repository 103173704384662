import { ChangeDetectionStrategy, Component, output, ViewEncapsulation } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonComponent } from '@shared/button/button.component';
import { HeaderImageComponent } from '@shared/header-image/header-image.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-career-header',
    standalone: true,
    imports: [
        AssetPipe,
        SectionWrapperComponent,
        HeaderImageComponent,
        ButtonComponent,
        NgOptimizedImage,
    ],
    templateUrl: './career-header.component.html',
    styleUrl: './career-header.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerHeaderComponent {
    public readonly assetPath = 'content/career/header';
    public readonly assetPathShared = 'shared';

    public seeJobOpenings = output();

    public onSeeJobOpeningsClick(): void {
        this.seeJobOpenings.emit();
    }
}
