import { NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ArrowButtonComponent } from '@shared/arrow-button/arrow-button.component';
import { ButtonComponent } from '@shared/button/button.component';
import { GridComponent } from '@shared/grid/grid.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { AppPath } from '@shared/routes/app-routing.const';
import { SliderEnableDisablePipe } from '@shared/slider-enable-disable/slider-enable-disable.pipe';
import { SliderEnableDisableService } from '@shared/slider-enable-disable/slider-enable-disable.service';
import feedbacksJson from 'src/assets/json/feedbacks.json';
import { PhotoCardComponent } from './photo-card/photo-card.component';
import { Feedback } from './our-clients-say.type';
import { BaseSliderComponent } from '@shared/slider-enable-disable/base-slider.component';

@Component({
    selector: 'jsdaddy-our-clients-say',
    standalone: true,
    imports: [
        AssetPipe,
        SliderEnableDisablePipe,
        ArrowButtonComponent,
        ButtonComponent,
        HeaderTitleComponent,
        PhotoCardComponent,
        GridComponent,
        NgOptimizedImage,
    ],
    providers: [SliderEnableDisableService],
    templateUrl: './our-clients-say.component.html',
    styleUrl: './our-clients-say.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OurClientsSayComponent extends BaseSliderComponent implements AfterViewInit {
    public slidesElement = viewChild<string, ElementRef<HTMLElement>>('slides', {
        read: ElementRef,
    });

    public slide = viewChild<string, ElementRef<HTMLElement>>('slide', { read: ElementRef });

    public readonly contactUsPath = `/${AppPath.CONTACT_US}`;
    public readonly feedbacks: Feedback[] = feedbacksJson;
}
