import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { WhyChooseCard } from './why-choose-card.type';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-why-choose-card[card]',
    standalone: true,
    imports: [AssetPipe, NgOptimizedImage],
    templateUrl: './why-choose-card.component.html',
    styleUrl: './why-choose-card.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhyChooseCardComponent {
    public card = input.required<WhyChooseCard>();
}
