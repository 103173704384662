import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    inject,
    input,
    computed,
} from '@angular/core';
import { CareerPosition } from '@content/career/career-positions/career-positions.types';
import { CareerDetailsService } from '../career-details.service';
import { CareerDetailsContent } from './career-details-content';
import { CareerDetailsScrollDirective } from '@content/career-details/career-details-scroll.directive';

@Component({
    selector: 'jsdaddy-career-details-content',
    standalone: true,
    imports: [CareerDetailsScrollDirective],
    templateUrl: './career-details-content.component.html',
    styleUrl: './career-details-content.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerDetailsContentComponent {
    public career = input.required<CareerPosition>();

    private readonly careerDetailsService = inject(CareerDetailsService);

    public tiles = computed(() => {
        const currentCareer = this.career();
        return [
            new CareerDetailsContent(currentCareer.responsibilities, 'Responsibilities'),
            new CareerDetailsContent(currentCareer.requirements, 'Requirements'),
            new CareerDetailsContent(currentCareer.niceToHave, 'Nice To Have'),
        ];
    });

    public onSectionChange(sectionId: string): void {
        this.careerDetailsService.changeSection(sectionId);
    }
}
