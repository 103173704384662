import { ChangeDetectionStrategy, Component, output, ViewEncapsulation } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { BorderButtonComponent } from '@shared/border-button/border-button.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { CareerOffer } from './career-offered.type';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-career-offered',
    standalone: true,
    imports: [
        AssetPipe,
        HeaderTitleComponent,
        SectionWrapperComponent,
        BorderButtonComponent,
        NgOptimizedImage,
    ],
    templateUrl: './career-offered.component.html',
    styleUrl: './career-offered.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerOfferedComponent {
    public watchOpening = output();
    public readonly assetPath = 'content/career/offered';
    public readonly assetPathShared = 'shared';
    public readonly offers: CareerOffer[] = [
        {
            title: 'REMOTE OR OFFICE WORK',
            text: 'Our infrastructure supports team members working from the office, or remotely from home, or following hybrid work policies.',
            asset: 'remote',
        },
        {
            title: 'SPORT ACTIVITIES',
            text: 'Generous annual vacation time including personal days, additional bonus holidays, and amazing company retreat getaways.',
            asset: 'basketball',
        },
        {
            title: 'HEALTH ASSISTANCE',
            text: 'Comprehensive extended health, dental & vision care for our permanent staff and their families. Free use of a fully equipped onsite gym.',
            asset: 'health-book',
        },
        {
            title: 'LEGAL SUPPORT',
            text: 'Competitive salaries, great benefits package, shares program, new hire relocation assistance, and bonus holidays.',
            asset: 'scales',
        },
        {
            title: 'WORK-LIFE BALANCE',
            text: 'Generous annual vacation time including personal days, additional bonus holidays, and amazing company retreat getaways.',
            asset: 'life-cycle',
        },
    ];

    public onWatchOpeningClick(): void {
        this.watchOpening.emit();
    }
}
