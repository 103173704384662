import { DOCUMENT, isPlatformServer, NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    input,
    output,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    signal,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ImageUrlPipe } from '@shared/image-url/image-url.pipe';
import { ResizeService } from '@shared/resize/resize.service';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { VisibilityPipe } from '@shared/visibility/visibility.pipe';
import { Navigation } from '../header.type';
import { MenuService } from './menu.service';
import { RightServicePagePipe } from './rigth-service-page.pipe';
import { ServicePageInfoMenuComponent } from './service-page-info-menu/service-page-info-menu.component';
import { WidthServicePagePipe } from './width-service-page.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';
import { screenSizeDesktop } from '@shared/screen-size/screen-size.const';

@Component({
    selector: 'jsdaddy-menu[navigation][width]',
    standalone: true,
    imports: [
        RouterLinkWithHref,
        SectionWrapperComponent,
        ServicePageInfoMenuComponent,
        AssetPipe,
        ImageUrlPipe,
        WidthServicePagePipe,
        RightServicePagePipe,
        VisibilityPipe,
        NgOptimizedImage,
        AnchorLabelPipe,
    ],
    providers: [MenuService],
    templateUrl: './menu.component.html',
    styleUrl: './menu.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements AfterViewInit {
    private readonly resizeService = inject(ResizeService);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly destroyRef = inject(DestroyRef);
    public readonly assetPath = 'header/menu';

    public closeMenu = output();
    public navigation = input.required<Navigation[]>();
    public width = input.required<number>();
    public menu = viewChild<string, ElementRef<HTMLElement>>('menu', { read: ElementRef });

    public isShowServiceInfo = signal(false);

    private readonly document = inject(DOCUMENT);
    public readonly screenSizeDesktop = screenSizeDesktop;
    public innerWidth = 0;
    public innerHeight = 0;

    public ngAfterViewInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const body: HTMLBodyElement | null = this.document.querySelector('body');
        if (!body) {
            return;
        }
        this.resizeService
            .createResizeSequence(body)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                this.innerWidth = value;
                this.innerHeight = this.menu()?.nativeElement?.offsetHeight ?? 0;
                this.cdr.detectChanges();
            });
    }
    public closeServiceInfo(event?: Event): void {
        event?.stopPropagation();
        this.isShowServiceInfo.set(false);
    }
    public showServiceInfo(event?: Event): void {
        event?.stopPropagation();
        this.isShowServiceInfo.set(true);
    }
    public close(event?: Event): void {
        this.closeMenu.emit();
        this.closeServiceInfo(event);
    }
}
