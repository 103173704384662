import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import {
    ApplicationConfig,
    APP_ID,
    ErrorHandler,
    APP_INITIALIZER,
    provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
    provideRouter,
    Router,
    TitleStrategy,
    UrlSerializer,
    withInMemoryScrolling,
    withViewTransitions,
} from '@angular/router';
import { ReadyForDiscussService } from '@header/ready-for-discuss/ready-for-discuss.service';
import { BaseHttpService } from '@libraries/base-http/base-http.service';
import { DOMAIN } from '@libraries/token/token';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { BodyStylesService } from '@shared/body-styles/body-styles.service';
import { BreadcrumbService } from '@shared/breadcrumbs/breadcrumbs.service';
import { ContactFormService } from '@shared/contact-form/contact-form.service';
import { CurrentRouteService } from '@shared/current-route/current-route.service';
import { HtmlPageTitleStrategy } from '@shared/html-page-title/html-page-title.strategy';
import { RecaptchaService } from '@shared/recaptcha/recaptcha.service';
import { ResizeService } from '@shared/resize/resize.service';
import { CustomUrlSerializer } from '@shared/url-serializer.service/url-serializer.service';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { CareerDetailsService } from '@content/career-details/career-details.service';
import { FormSendingDialogService } from '@shared/form-sending-dialog/form-sending-dialog.service';
import { EMPTY } from 'rxjs';
import { provideClientHydration } from '@angular/platform-browser';
import { MetaTagsService } from '@shared/meta-tags/meta-tags.service';
import { RecaptchaInterceptor } from '@shared/recaptcha/recaptcha.interceptor';

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: APP_ID, useValue: 'jsdaddy-updated-corporate-site' },
        provideExperimentalZonelessChangeDetection(),
        provideClientHydration(),
        ReadyForDiscussService,
        CurrentRouteService,
        ResizeService,
        BreadcrumbService,
        BaseHttpService,
        ContactFormService,
        RecaptchaService,
        BodyStylesService,
        CareerDetailsService,
        FormSendingDialogService,
        MetaTagsService,
        provideHttpClient(withFetch(), withInterceptors([RecaptchaInterceptor])),
        provideRouter(
            routes,
            withViewTransitions({ skipInitialTransition: true }),
            withInMemoryScrolling({ anchorScrolling: 'enabled' })
        ),
        {
            provide: DOMAIN,
            useValue: [`${environment.serverDomain}`, `${environment.clientDomain}`],
        },
        { provide: ErrorHandler, useValue: createErrorHandler({ showDialog: false }) },
        { provide: TraceService, deps: [Router] },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => EMPTY,
            deps: [TraceService],
            multi: true,
        },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        { provide: TitleStrategy, useClass: HtmlPageTitleStrategy },
    ],
};
