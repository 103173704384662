import { CareerDetailsSection } from '../career-details.section';
import { CareerDetailsTitlesType } from '@content/career-details/career-details.type';

export class CareerDetailsContent extends CareerDetailsSection {
    public readonly texts: string[];

    public constructor(texts: string[], title: CareerDetailsTitlesType) {
        super(title);
        this.texts = texts;
    }
}
