import { ChangeDetectionStrategy, Component, output, ViewEncapsulation } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { BorderButtonComponent } from '@shared/border-button/border-button.component';
import { AppPath } from '@shared/routes/app-routing.const';
import servicesJson from 'src/assets/json/services.json';
import { NgOptimizedImage } from '@angular/common';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-service-page-info-menu',
    standalone: true,
    imports: [
        RouterLinkWithHref,
        AssetPipe,
        BorderButtonComponent,
        NgOptimizedImage,
        AnchorLabelPipe,
    ],
    templateUrl: './service-page-info-menu.component.html',
    styleUrl: './service-page-info-menu.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicePageInfoMenuComponent {
    public closeParentMenu = output<Event>();
    public closeSelf = output<Event>();
    public readonly contactUs = `/${AppPath.CONTACT_US}`;
    public readonly services = `/${AppPath.SERVICES}`;
    public readonly assetPath = 'header/menu';
    public readonly servicesJson = servicesJson;

    public close(event: Event): void {
        this.closeParentMenu.emit(event);
    }
    public closeSelfEmit(event: Event): void {
        this.closeSelf.emit(event);
    }
}
