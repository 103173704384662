import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { TransformRotatePipe } from '@shared/arrow-button/transform-rotate/transform-rotate.pipe';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ArrowButtonDirectionType, ArrowButtonType } from '@shared/arrow-button/arrow-button.type';

@Component({
    selector: 'jsdaddy-arrow-button',
    standalone: true,
    imports: [TransformRotatePipe, AssetPipe, NgOptimizedImage, NgClass],
    templateUrl: './arrow-button.component.html',
    styleUrl: './arrow-button.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowButtonComponent {
    public direction = input<ArrowButtonDirectionType>('forward');
    public type = input<ArrowButtonType>('regular');
    public rotateDeg = input(0);
    public enabled = input(true);

    public readonly assetPath = 'shared/arrow-button';
}
