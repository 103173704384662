import { Pipe, PipeTransform } from '@angular/core';
import { ShowCaseType } from '@shared/show-cases-list/show-cases-list.type';

@Pipe({
    name: 'showCaseIsMobile',
    standalone: true,
})
export class ShowCaseIsMobilePipe implements PipeTransform {
    public transform(showCaseType: ShowCaseType | undefined): boolean {
        if (!showCaseType) {
            return false;
        }
        return ['mobile', 'portrait-tablet'].includes(showCaseType);
    }
}
