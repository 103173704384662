import { Pipe, PipeTransform } from '@angular/core';
import { ShowCaseType } from '@shared/show-cases-list/show-cases-list.type';

@Pipe({
    name: 'frame',
    standalone: true,
})
export class FramePipe implements PipeTransform {
    public transform(type: ShowCaseType | undefined, needHalf: boolean): string {
        if (!type) {
            return '';
        }
        const fileName = needHalf ? `${type}-half` : type;

        return `assets/images/shared/show-cases/shared/${fileName}-frame.webp`;
    }
}
