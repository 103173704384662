import { CareerDetailsTitlesType } from '@content/career-details/career-details.type';

export class CareerDetailsSection {
    public readonly title: CareerDetailsTitlesType;
    public readonly id: string;
    public constructor(title: CareerDetailsTitlesType) {
        this.title = title;
        this.id = this.title.replaceAll(' ', '-').toLowerCase();
    }
}
