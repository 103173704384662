import { HttpClient } from '@angular/common/http';
import { ContactFormComponent } from '@shared/contact-form/contact-form.component';
import { EmailPipe } from '@shared/email/email.pipe';
import { isPlatformServer, DatePipe, NgOptimizedImage } from '@angular/common';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    inject,
    OnInit,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    signal,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { interval, map, startWith } from 'rxjs';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { GridComponent } from '@shared/grid/grid.component';
import { BreadcrumbsComponent } from '@shared/breadcrumbs/breadcrumbs.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'jsdaddy-contact-us',
    standalone: true,
    imports: [
        DatePipe,
        SectionWrapperComponent,
        AssetPipe,
        EmailPipe,
        ContactFormComponent,
        GridComponent,
        BreadcrumbsComponent,
        NgOptimizedImage,
    ],
    providers: [HttpClient],
    templateUrl: './contact-us.component.html',
    styleUrl: './contact-us.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsComponent implements OnInit {
    public readonly locationItems = [
        {
            title: 'Bulgaria, Sveti Vlas',
            timeZone: 'UTC+3',
        },
        {
            title: 'Ukraine, Ivano-Frankivsk',
            timeZone: 'UTC+3',
        },
    ];

    public readonly emailItems = [
        {
            title: "Let's discuss",
            email: 'sales@jsdaddy.io',
        },
        {
            title: 'Be part of team',
            email: 'career@jsdaddy.io',
        },
    ];

    public readonly assetPath = 'content/contact-us';
    public readonly destroyRef = inject(DestroyRef);

    private readonly platformId = inject<string>(PLATFORM_ID);

    public currentTime: WritableSignal<Date | null> = signal(null);

    public ngOnInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        interval(1000)
            .pipe(
                startWith(0),
                map(() => new Date()),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((date) => {
                this.currentTime.set(date);
            });
    }
}
