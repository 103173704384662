import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    inject,
    input,
    booleanAttribute,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '@shared/button/button.component';
import { FormSendingDialogComponent } from '@shared/form-sending-dialog/form-sending-dialog.component';
import { InputComponent } from '@libraries/input/input.component';
import { ContactFormLabelPipe } from './contact-form-label.pipe';
import { ContactFormItem } from './contact-form.types';
import { ContactFormPipe } from './contact-form.pipe';
import { ContactFormService } from './contact-form.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'jsdaddy-contact-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ButtonComponent,
        InputComponent,
        ContactFormPipe,
        ContactFormLabelPipe,
        FormSendingDialogComponent,
    ],
    templateUrl: './contact-form.component.html',
    styleUrl: './contact-form.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent {
    public withSteps = input(false, { transform: booleanAttribute });

    private readonly service = inject(ContactFormService);

    public form = this.service.form;

    public readonly currentStep = toSignal(this.service.currentStep$);

    public readonly steps: ContactFormItem[] = this.service.steps;

    public next(): void {
        this.service.next();
    }

    public back(): void {
        this.service.back();
    }

    public sendEmail(): void {
        this.service.sendEmail();
    }

    public resetForm(): void {
        this.service.resetForm();
    }
}
