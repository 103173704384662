import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { AppPath } from '@shared/routes/app-routing.const';
import { LetsDoDirective } from './lets-do-work.directive';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-lets-do-work',
    standalone: true,
    imports: [RouterLinkWithHref, AssetPipe, LetsDoDirective, NgOptimizedImage],
    templateUrl: './lets-do-work.component.html',
    styleUrl: './lets-do-work.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LetsDoWorkComponent {
    public readonly contactUs = `/${AppPath.CONTACT_US}`;
}
