import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    ViewEncapsulation,
    inject,
    viewChild,
} from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ArrowButtonComponent } from '@shared/arrow-button/arrow-button.component';
import { BorderButtonComponent } from '@shared/border-button/border-button.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { AppPath } from '@shared/routes/app-routing.const';
import { RowBlackWhiteWrapperComponent } from '@shared/row-black-white-wrapper/row-black-white-wrapper.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { ShowCasesService } from '@shared/show-cases/show-cases.service';
import { SliderEnableDisablePipe } from '@shared/slider-enable-disable/slider-enable-disable.pipe';
import { SliderEnableDisableService } from '@shared/slider-enable-disable/slider-enable-disable.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { BaseSliderComponent } from '@shared/slider-enable-disable/base-slider.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-service-projects',
    standalone: true,
    imports: [
        AssetPipe,
        HeaderTitleComponent,
        SectionWrapperComponent,
        RowBlackWhiteWrapperComponent,
        ArrowButtonComponent,
        BorderButtonComponent,
        SliderEnableDisablePipe,
        NgOptimizedImage,
    ],
    providers: [SliderEnableDisableService, ShowCasesService],
    templateUrl: './service-projects.component.html',
    styleUrl: './service-projects.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceProjectsComponent extends BaseSliderComponent {
    public slidesElement = viewChild<string, ElementRef<HTMLElement>>('slides', {
        read: ElementRef,
    });

    public slide = viewChild<string, ElementRef<HTMLElement>>('slide', { read: ElementRef });

    public readonly buttonTypeLink = 'link';
    public readonly caseStudies = `/${AppPath.CASE_STUDIES}`;
    public readonly assetPath = 'content/services/projects';

    private readonly showCasesService = inject(ShowCasesService);
    public readonly showCases = toSignal(this.showCasesService.getShowCases());
}
