<div class="card">
    <div class="title">
        <span class="h3-text full-white">{{ card()?.title }}</span>
        <div class="icon-wrapper">
            <div class="delimiter"></div>
            <img
                width="44"
                height="44"
                [ngSrc]="card()?.icon | asset: 'content/home/choose-us'"
                [alt]="card()?.title" />
        </div>
    </div>
    <span class="description span">
        {{ card()?.description }}
    </span>
</div>
