@if (positions()) {
    <jsdaddy-grid class="grid" hideDesktop hideTablet />

    <div class="row">
        <jsdaddy-header-title
            black
            class="header-title"
            title="Available Positions"
            subtitle="All job openings"
            needStar />
    </div>
    @for (position of positions(); track position.position; let i = $index) {
        <div [ngClass]="['position-wrapper', 'column', 'pos-rel', position.styleClass]">
            @if (i | careerPositions: positions() : position) {
                <span class="uppercase position-name ls-25 h5 text">{{ position.position }}</span>
            }
            <div class="position column pos-rel">
                <img
                    class="flame"
                    alt="Flame"
                    [ngSrc]="'flame' | asset: 'content/career/positions'"
                    width="56"
                    height="56" />
                <span class="uppercase position-title ls-25">{{ position.title }}</span>
                <div class="row jc-sb">
                    <div class="row chips">
                        @for (solution of position.stack; track solution) {
                            <jsdaddy-chip class="row" [chip]="solution" />
                        }
                    </div>
                    <ng-container
                        *ngTemplateOutlet="
                            apply;
                            context: { $implicit: 'hide-mobile', position }
                        " />
                </div>
                <ng-container
                    *ngTemplateOutlet="
                        apply;
                        context: { $implicit: 'hide-desktop hide-tablet ais-end', position }
                    " />
            </div>
        </div>
    }
    <div class="column want-block">
        <span class="title ls-25">WANT TO GET A<br />JOB?</span>
        <span class="h4-text">CHOOSE<br />YOUR<br />VACANCY</span>
    </div>
}

<ng-template #apply let-styleClass let-position="position">
    <a
        [attr.aria-label]="'Contact us' | anchorLabel"
        [routerLink]="[careerPath, position.slug]"
        class="ais-end">
        <div [ngClass]="['row apply-block', styleClass]">
            <jsdaddy-border-button title="APPLY" />
            <img
                class="arrow"
                [ngSrc]="'arrow-top-right-black' | asset: 'shared'"
                width="24"
                height="24"
                alt="Arrow" />
        </div>
    </a>
</ng-template>
