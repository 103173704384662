import { Routes } from '@angular/router';
import { AppPath } from '@shared/routes/app-routing.const';
import { metaTagsResolver } from '@shared/meta-tags/meta-tags.resolver';
import { HomeComponent } from '@content/home/home.component';
import { CareerDetailsComponent } from '@content/career-details/career-details.component';
import { CareerComponent } from '@content/career/career.component';
import { CaseStudiesComponent } from '@content/case-studies/case-studies.component';
import { CaseComponent } from '@content/case/case.component';
import { NotFoundComponent } from '@content/not-found/not-found.component';
import { ContactUsComponent } from '@content/contact-us/contact-us.component';
import { ServiceComponent } from '@content/service/service.component';

const baseUrl = 'https://jsdaddy.io/';

export const routes: Routes = [
    {
        path: AppPath.EMPTY,
        children: [
            {
                path: AppPath.EMPTY,
                component: HomeComponent,
                data: {
                    title: 'JSDaddy.io - High-Power Business Solutions',
                    breadcrumb: 'home',
                    metaTags: {
                        description:
                            "Achieve your goals with JSDaddy.io's expertise in CRM systems and Multi-Platform applications. Discover our services in web applications, multi-platform applications, CRM solutions, and consulting.",
                        keywords:
                            'JSDaddy, business solutions, CRM systems, multi-platform applications, web applications, consulting, software development, HTML, CSS, Angular, React, Node.js, Nest.js, Express.js, Flutter, Dart',
                        'og:url': baseUrl,
                        'og:title': 'JSDaddy.io - High-Power Business Solutions',
                        'og:description':
                            "Achieve your goals with JSDaddy.io's expertise in CRM systems and Multi-Platform applications. Discover our services in web applications, multi-platform applications, CRM solutions, and consulting.",
                        // 'og:image': 'https://jsdaddy.io/og-image.jpg'
                    },
                },
                resolve: {
                    metaTags: metaTagsResolver,
                },
            },
            {
                path: AppPath.CAREER,
                children: [
                    {
                        path: AppPath.EMPTY,
                        component: CareerComponent,
                        data: {
                            title: 'JSDaddy.io - Career Opportunities',
                            metaTags: {
                                description:
                                    'Join JSDaddy.io and be a part of our innovative team. Explore career opportunities and find out how you can contribute to our success with your skills and experience.',
                                keywords:
                                    'JSDaddy, career, job opportunities, join our team, employment, career growth',
                                'og:url': `${baseUrl}${AppPath.CAREER}`,
                                'og:title': 'JSDaddy.io - Career Opportunities',
                                'og:description':
                                    'Join JSDaddy.io and be a part of our innovative team. Explore career opportunities and find out how you can contribute to our success with your skills and experience.',
                                // 'og:image': 'https://jsdaddy.io/career-og-image.jpg'
                            },
                        },
                        resolve: {
                            metaTags: metaTagsResolver,
                        },
                    },
                    {
                        path: AppPath.CAREER_DETAILS,
                        component: CareerDetailsComponent,
                        resolve: {
                            metaTags: metaTagsResolver,
                        },
                    },
                ],
                data: { breadcrumb: 'career' },
            },
            {
                path: AppPath.CASE_STUDIES,
                children: [
                    {
                        path: AppPath.EMPTY,
                        component: CaseStudiesComponent,
                        data: {
                            title: 'JSDaddy.io - Case Studies',
                            metaTags: {
                                description:
                                    'Explore our detailed case studies to see how JSDaddy.io has successfully implemented business solutions for various clients. Learn about the challenges, solutions, and results.',
                                keywords:
                                    'JSDaddy, case studies, business solutions, client success stories, implementation, results, HTML, CSS, Angular, React, Node.js, Nest.js, Express.js, Flutter, Dart',
                                'og:url': `${baseUrl}${AppPath.CASE_STUDIES}`,
                                'og:title': 'JSDaddy.io - Case Studies',
                                'og:description':
                                    'Explore our detailed case studies to see how JSDaddy.io has successfully implemented business solutions for various clients. Learn about the challenges, solutions, and results.',
                                // 'og:image': 'https://jsdaddy.io/case-studies-og-image.jpg'
                            },
                        },
                        resolve: {
                            metaTags: metaTagsResolver,
                        },
                    },
                    {
                        path: AppPath.CASE,
                        component: CaseComponent,
                        resolve: {
                            metaTags: metaTagsResolver,
                        },
                    },
                ],
                data: { breadcrumb: 'case studies' },
            },
            {
                path: AppPath.CONTACT_US,
                component: ContactUsComponent,
                data: {
                    title: 'JSDaddy.io - Contact Us',
                    breadcrumb: 'contact us',
                    metaTags: {
                        description:
                            'Get in touch with JSDaddy.io to discuss your project needs and learn more about our services. Our team is here to assist you with any questions or inquiries.',
                        keywords:
                            'JSDaddy, contact us, customer support, project inquiries, business solutions',
                        'og:url': `${baseUrl}${AppPath.CONTACT_US}`,
                        'og:title': 'JSDaddy.io - Contact Us',
                        'og:description':
                            'Get in touch with JSDaddy.io to discuss your project needs and learn more about our services. Our team is here to assist you with any questions or inquiries.',
                        // 'og:image': 'https://jsdaddy.io/contact-us-og-image.jpg'
                    },
                },
                resolve: {
                    metaTags: metaTagsResolver,
                },
            },
            {
                path: AppPath.SERVICE,
                component: ServiceComponent,
            },
            {
                path: AppPath.ALL,
                component: NotFoundComponent,
                data: {
                    title: 'JSDaddy.io - High-Power Business Solutions',
                    metaTags: {
                        description:
                            "Achieve your goals with JSDaddy.io's expertise in CRM systems and Multi-Platform applications. Discover our services in web applications, multi-platform applications, CRM solutions, and consulting.",
                        keywords:
                            'JSDaddy, business solutions, CRM systems, multi-platform applications, web applications, consulting, software development, HTML, CSS, Angular, React, Node.js, Nest.js, Express.js, Flutter, Dart',
                        'og:url': baseUrl,
                        'og:title': 'JSDaddy.io - High-Power Business Solutions',
                        'og:description':
                            "Achieve your goals with JSDaddy.io's expertise in CRM systems and Multi-Platform applications. Discover our services in web applications, multi-platform applications, CRM solutions, and consulting.",
                        // 'og:image': 'https://jsdaddy.io/og-image.jpg'
                    },
                },
                resolve: {
                    metaTags: metaTagsResolver,
                },
            },
        ],
    },
];
