import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { ImageUrlPipe } from '@shared/image-url/image-url.pipe';
import { TechnologiesSliderComponent } from '@shared/technologies-slider/technologies-slider.component';

@Component({
    selector: 'jsdaddy-service-technologies',
    standalone: true,
    imports: [AssetPipe, ImageUrlPipe, TechnologiesSliderComponent, HeaderTitleComponent],
    templateUrl: './service-technologies.component.html',
    styleUrl: './service-technologies.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceTechnologiesComponent {
    public readonly assetPath = 'content/services/technologies';
    public readonly technologies = [
        'angular',
        'flutter',
        'react',
        'nodejs',
        'js-ts',
        'nestjs',
        'dart',
    ];
}
