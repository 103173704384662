<jsdaddy-header-image
    title="JOIN US"
    subtitle="BE PART OF OUR TEAM"
    isSubtitleMoreThanText
    [asset]="'joinus' | asset: assetPath : true" />
<jsdaddy-button (click)="onSeeJobOpeningsClick()" type="primary-yellow" title="See Job Openings" />
<jsdaddy-section-wrapper>
    <div class="row block-text pos-rel">
        <img
            alt="Obj plus left"
            class="obj-plus obj-plus-left hide-tablet hide-mobile"
            [ngSrc]="'obj-plus-header' | asset: assetPath"
            width="96"
            height="134" />
        <img
            alt="Obj plus right"
            class="obj-plus obj-plus-right hide-tablet hide-mobile"
            [ngSrc]="'obj-plus-header' | asset: assetPath"
            width="96"
            height="134" />
        <div class="column">
            <span class="h3-text">Welcome to JS Daddy</span>
            <span class="divider"></span>
            <img
                [ngSrc]="'logo' | asset: assetPathShared"
                width="54"
                height="32"
                alt="JSDaddy logo" />
        </div>
        <span class="span">
            When you join us, you'll be working in a tight-knit team of passionate, talented
            professionals who love their craft, creative collaboration, and building great things
            together. We're inspired by and build on each other's ideas and experience to achieve
            our common goal of excellence in the products we work on. We're always actively seeking
            the industry's top talent, and encourage you to apply even when our open roles may not
            match your qualifications. We'll keep your resume on file for consideration when new
            positions open up in the future.
        </span>
    </div>
</jsdaddy-section-wrapper>
