export const AppPath = {
    EMPTY: '',
    CAREER: 'career',
    CAREER_DETAILS: ':slug',
    SERVICE: 'services/:slug',
    SERVICES: 'services',
    CONTACT_US: 'contact-us',
    CASE_STUDIES: 'case-studies',
    CASE: ':slug',
    ALL: '**',
};
