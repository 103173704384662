import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-linkedin',
    standalone: true,
    imports: [AssetPipe, NgOptimizedImage],
    templateUrl: './linkedin.component.html',
    styleUrl: './linkedin.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedinComponent {}
