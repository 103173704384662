import { Injectable } from '@angular/core';
import { screenSizeDesktop, screenSizeMobile } from '@shared/screen-size/screen-size.const';

@Injectable()
export class MenuService {
    public getWidthServicePage(value: number | null, innerWidth: number): number {
        if (innerWidth <= screenSizeMobile) {
            return innerWidth - 44;
        }

        let widthServicePage = 402;

        if (innerWidth > screenSizeDesktop) {
            widthServicePage = 354;
        }
        return (innerWidth - Number(value)) / 2 + widthServicePage;
    }
}
