import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CareerDetailsSection } from './career-details.section';
import { CareerDetailsTitlesType } from '@content/career-details/career-details.type';

@Injectable()
export class CareerDetailsService {
    private sectionSource$$ = new BehaviorSubject<string>('');

    public currentSection$ = this.sectionSource$$.asObservable();

    public sections: CareerDetailsSection[] = [
        new CareerDetailsSection('Project Details'),
        new CareerDetailsSection('Responsibilities'),
        new CareerDetailsSection('Requirements'),
        new CareerDetailsSection('Nice To Have'),
        new CareerDetailsSection('Send your CV'),
    ];

    public getSection(
        careerDetailsTitle: CareerDetailsTitlesType
    ): CareerDetailsSection | undefined {
        return this.sections.find(({ title }) => title === careerDetailsTitle);
    }

    public changeSection(section: string): void {
        this.sectionSource$$.next(section);
    }
}
